import Mainroutes from "./routes/routes";
import "./App.css";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
function App() {
  const [isReadArticle, setIsReadArticle] = useState<boolean>(false);
  useEffect(() => {
    const url = window.location.href;
    if (url.includes("id=")) {
      setIsReadArticle(true);
    }
  }, []);

  return (
    <>
      {!isReadArticle && (
        <Helmet>
          <title>Advance</title>
          <meta
            property="og:image"
            content="https://advance.minorityafrica.org/static/media/africa.f55d7d7671441b219232.png"
          />
        </Helmet>
      )}
      <Mainroutes />
    </>
  );
}
export default App;
