import { createSlice } from "@reduxjs/toolkit";
import { getData } from "./metricsActions";

interface publishersProps {
  loading: boolean;
  error: boolean;
  success: boolean;
  users: [];
  numCountries: number;
  articles: [];
  advancersByCountry: [];
  republicationFrequency: [];
  monthlyGrowthTrends: [];
  advancerRepublishedArticles: [];
  topArticles: [];
}

const initialState: publishersProps = {
  loading: false,
  error: false,
  success: false,
  users: [],
  numCountries: 0,
  articles: [],
  advancersByCountry: [],
  republicationFrequency: [],
  monthlyGrowthTrends: [],
  advancerRepublishedArticles: [],
  topArticles: [],
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.users = [];
    },

    setNumCountries: (state, action) => {
      state.numCountries = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.users = {
        ...action.payload.users,
      };
      state.articles = {
        ...action.payload.articles,
      };

      state.republicationFrequency = action.payload.republicationFrequency;
      state.advancersByCountry = action.payload.advancersByCountry;
      state.monthlyGrowthTrends = action.payload.monthlyGrowthTrends;
      state.numCountries = action.payload.numCountries;
      state.advancerRepublishedArticles =
        action.payload.advancerRepublishedArticles;
      state.topArticles = action.payload.topArticles;
    });

    builder.addCase(getData.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});
export const { reset, setNumCountries } = metricsSlice.actions;
export default metricsSlice.reducer;
