/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HStack,
  Img,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Stack,
  IconButton,
  Center,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { HiPencil } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import RepublishedTypes from "../../../model/republished.types";
import { formatDate } from "../../../utils/date";
import Loader from "../../loader";
import { createArticleState } from "../../state/atoms/create-article-atoms";
import Deletarticle from "../DeleteArticle/deleteArticle";
import PaginationBtn from "../../pagination/PaginationBtn";
import { AppDispatch } from "../../../state/store/store";
import { useDispatch } from "react-redux";
import { setAticlePage } from "../../../state/features/publishers-articles/articlesSlice";
import { getAllPublisherArticles } from "../../../state/features/publishers-articles/articleActions";
import useAuth from "../../../hooks/useAuth";
import { BsFilesAlt } from "react-icons/bs";

type ArticlesCardProps = {
  publisherArticles: RepublishedTypes;
  isLoading: boolean;
};

const ArticlesList = ({ publisherArticles, isLoading }: ArticlesCardProps) => {
  const { loggedUser } = useAuth();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const id = loggedUser?.userId as string;
  const [changeArticleMode, setChangeArticleMode] =
    useRecoilState(createArticleState);
  const navigation = useNavigate();
  const onPageChange = (page: number) => {
    if (page) {
      dispatch(setAticlePage(page));
      dispatch(getAllPublisherArticles({ page, id, approved: undefined }));
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : publisherArticles?.list && publisherArticles?.list.length <= 0 ? (
        <Stack justify="center" align="center" mt="11em" w="100%">
          <Stack justify="center" align="center">
            <BsFilesAlt fontSize="7em" color="#CCCCCC" />
            <Text
              textAlign="center"
              color="#666666"
              fontSize="20px"
              fontWeight="400"
              fontStyle="normal"
              lineHeight="28px"
            >
              Looks like you haven't posted any articles yet.
              <br />
              <Text as="span" textAlign="center">
                "Click theCreate Article" to begin
              </Text>
            </Text>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <TableContainer py="1em">
            <Table variant="unstyled">
              <Thead borderBottom="1px" borderBottomColor="#F2F2F2">
                <Tr
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="18px"
                  color=" #666666;"
                  textAlign="center"
                >
                  <Th textTransform="none">Title</Th>
                  <Th textTransform="none">Status</Th>
                  <Th textTransform="none">Tags</Th>
                  <Th textTransform="none">Read</Th>
                  <Th textTransform="none">Views</Th>
                  <Th textTransform="none">Date posted</Th>
                </Tr>
              </Thead>
              <Tbody>
                {publisherArticles &&
                  publisherArticles.list &&
                  publisherArticles.list.map(
                    (articleList: any, index: number) => {
                      const chageMode = () => {
                        setChangeArticleMode({
                          ...changeArticleMode,
                          articleMode: "edit",
                        });
                      };
                      const editArticle = () => {
                        navigation(
                          `/dashboard/edit-article/${articleList._id}/${params.routeId}`,
                        );
                        chageMode();
                      };
                      let statusColor =
                        articleList?.approved === "approved"
                          ? "green.400"
                          : "red.500";
                      if (articleList?.approved === "review") {
                        statusColor = "gray.500";
                      }
                      return (
                        <Tr textAlign="center" key={articleList._id}>
                          <Td
                            cursor="pointer"
                            onClick={() => {
                              navigation(
                                `/dashboard/articlemetrics/${
                                  articleList && articleList._id
                                }/${params.routeId}`,
                              );
                            }}
                          >
                            <HStack w="100%">
                              <Text
                                fontSize="14px"
                                lineHeight="20xp"
                                fontWeight="400"
                                color="#666666"
                              >
                                {(publisherArticles.currentPage - 1) * 10 +
                                  index +
                                  1}
                              </Text>
                              {articleList &&
                                articleList.articleMedia &&
                                articleList.articleMedia.map((media: any) => {
                                  const imageUrl = media && media?.name;
                                  return (
                                    <Img
                                      src={imageUrl}
                                      h="2.5em"
                                      w="5em"
                                      borderRadius="lg"
                                    />
                                  );
                                })}

                              <Center
                                fontSize="14px"
                                lineHeight="20xp"
                                fontWeight="400"
                                color="#666666"
                              >
                                {articleList && articleList.title}
                              </Center>
                              <Stack px="1em" py="1em"></Stack>
                            </HStack>
                          </Td>
                          <Td
                            fontSize="14px"
                            lineHeight="20xp"
                            fontWeight="400"
                            color="#666666"
                          >
                            <Text color={statusColor}>
                              {articleList?.approved}
                            </Text>
                          </Td>
                          <Td alignItems="center">
                            {articleList &&
                              articleList?.categories.map(
                                (tag: any, index: any) => {
                                  return (
                                    <Tag
                                      size="md"
                                      bg="#EEEBFA"
                                      color="#4C2DB1"
                                      fontSize="12px"
                                      lineHeight="18px"
                                      borderRadius="full"
                                      marginLeft={index > 0 ? "3" : ""}
                                    >
                                      <TagLabel>{tag.name}</TagLabel>
                                    </Tag>
                                  );
                                },
                              )}
                          </Td>
                          <Td
                            fontSize="14px"
                            lineHeight="20xp"
                            fontWeight="400"
                            color="#666666"
                          >
                            {articleList && articleList.read}
                          </Td>
                          <Td
                            fontSize="14px"
                            lineHeight="20xp"
                            fontWeight="400"
                            color="#666666"
                          >
                            {articleList && articleList.viewed}
                          </Td>
                          <Td
                            fontSize="14px"
                            lineHeight="20xp"
                            fontWeight="400"
                            color="#666666"
                          >
                            {formatDate(
                              new Date(articleList && articleList.createdAt),
                            )}
                          </Td>
                          <Td>
                            <Deletarticle
                              articleId={articleList && articleList._id}
                            />
                            <IconButton
                              aria-label="Search database"
                              bg="#fff"
                              icon={<HiPencil color="#CCCCCC" />}
                              mr="4"
                              onClick={editArticle}
                            />
                          </Td>
                        </Tr>
                      );
                    },
                  )}
              </Tbody>
            </Table>
          </TableContainer>
          {publisherArticles.totalPages > 1 && (
            <PaginationBtn
              totalPages={publisherArticles.totalPages}
              currentPage={publisherArticles?.page?.page}
              onPageChange={onPageChange}
            />
          )}
        </Stack>
      )}
    </>
  );
};
export default ArticlesList;
