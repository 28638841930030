import { createSlice } from "@reduxjs/toolkit";
import { search } from "./searchActions";
import { ArticleType } from "../../../model/republished.types";

type PublisherAdvancerType = {
  name: string;
  surname: string;
  email: string;
  _id: string;
  profilePicture: string;
  isPublisher: boolean;
  countryCode: string;
};

type CategoryType = {
  name: string;
  _id: string;
};

interface SearchProps {
  loading: boolean;
  searchValue: string;
  error: boolean;
  success: boolean;
  articles: ArticleType[];
  publishers: PublisherAdvancerType[];
  advancers: PublisherAdvancerType[];
  categories: CategoryType[];
}

const initialState: SearchProps = {
  loading: false,
  searchValue: "",
  error: false,
  success: false,
  articles: [],
  advancers: [],
  publishers: [],
  categories: [],
};

export const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.articles = initialState.articles;
      state.advancers = initialState.advancers;
      state.publishers = initialState.publishers;
      state.categories = initialState.categories;
    },

    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(search.pending, (state) => {
      state.loading = true;
      state.advancers = initialState.advancers;
    });

    builder.addCase(search.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.advancers = action.payload?.advancers;

      state.publishers = action.payload?.publishers;

      state.articles = action.payload?.articles;
      state.categories = action.payload?.categories;
    });

    builder.addCase(search.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.articles = initialState.articles;
      state.advancers = initialState.advancers;
      state.publishers = initialState.publishers;
      state.categories = initialState.categories;
    });
  },
});
export const { reset, setSearchValue } = searchSlice.actions;
export { PublisherAdvancerType, CategoryType };
export default searchSlice.reducer;
