import { createSlice } from "@reduxjs/toolkit";
import advancerTypes from "../../../model/advancerTypes";
import { getAllAvancers } from "./advancersActions";

interface IAdvancersProps {
  loading: boolean;
  error: boolean;
  success: boolean;
  advancersData: advancerTypes;
}

const initialState: IAdvancersProps = {
  loading: false,
  error: false,
  success: false,
  advancersData: {
    totalAdvancers: 0,
    page: 1,
    currentPage: 1,
    totalPages: 0,
    list: [],
  },
};

export const advancersSlice = createSlice({
  name: "advancers",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.advancersData = initialState.advancersData;
    },
    setAdvancersPage: (state, action) => {
      state.advancersData.page = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllAvancers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllAvancers.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.advancersData = {
        ...action.payload,
        page: action.meta.arg,
      };
    });

    builder.addCase(getAllAvancers.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.advancersData = initialState.advancersData;
    });
  },
});

export const { reset, setAdvancersPage } = advancersSlice.actions;
export default advancersSlice.reducer;
