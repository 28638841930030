import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Link,
  Text,
  Center,
  Stack,
  Heading,
  useToast,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Input,
  Tooltip,
  IconButton,
  useClipboard,
} from "@chakra-ui/react";
import {
  getAdvancerDetails,
  offlineRepublish,
  rePublishArticle,
} from "../../services/advancer-services";
import useAuth from "../../hooks/useAuth";
import AdvancerObject from "../../model/advancerDetails.types";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";
import PublisherAticle from "../../model/PublisherArticle";
import { CopyIcon, CheckCircleIcon } from "@chakra-ui/icons";

type loaderState = {
  loading: boolean;
};

const initLoader: loaderState = {
  loading: false,
};

const Republish = ({ _id: articleId }: PublisherAticle) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [advancerdetails, setAdvancerDetails] = useState<AdvancerObject>();
  const { loggedUser } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const param = useParams();
  const id = loggedUser?.userId && loggedUser.userId;
  const [loading, setLoading] = useState(initLoader.loading);

  const { data: advancerDetails, isLoading } = useSWR(
    `/advancerDashboard/getAdvancerById/${id}`,
    () => getAdvancerDetails(id as string),

    {
      suspense: false,
      revalidateIfStale: true,
      refreshInterval: 4000,
    },
  );

  useEffect(() => {
    if (advancerDetails) {
      setAdvancerDetails(advancerDetails);
    }
  }, [advancerDetails]);

  const onRepublishArticle = async () => {
    if (!id) return;
    setLoading(true);
    const results = await rePublishArticle(id, articleId);
    if (results) {
      setLoading(false);
      toast({
        title: "Republish Successful",
        position: "top",
        description: "Article Successfully Republished",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } else {
      setLoading(false);
      toast({
        title: "Failed",
        description: "Failed to republish Article",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const onOfflineRepublish = async () => {
    if (!id) return;
    setLoading(true);
    const results = await offlineRepublish(id, articleId);
    console.log(articleId);
    if (results) {
      setLoading(false);
      toast({
        title: "Taking you to article",
        position: "top",
        description: "You can safely copy and republish article",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      setTimeout(() => {
        navigate(`/dashboard/advancer/article/${param.routeId}/${articleId}`, {
          state: { republish: true },
        });
      }, 2000);
    } else {
      setLoading(false);
      toast({
        title: "Failed",
        description: "Failed to republish Article",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const addWebsite = () => {
    navigate(`/dashboard/settings/${param.routeId}`);
  };
  const { hasCopied, onCopy } = useClipboard(
    `https://advance.minorityafrica.org/article?id=${articleId}`,
  );

  return (
    <>
      <Link
        onClick={onOpen}
        bg="#fff"
        color="#ED8278"
        fontSize="12px"
        lineHeight="18px"
        textAlign="right"
        fontWeight="400"
      >
        Republish
      </Link>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent rounded="lg">
          <ModalCloseButton />
          <ModalBody p="3em">
            <Heading
              color="#1A1A1A"
              textAlign="center"
              fontSize="30px"
              fontWeight={400}
              lineHeight="32px"
            >
              Republish Article
            </Heading>
            <Text
              color="#666666"
              fontSize="16px"
              textAlign="center"
              lineHeight="24px"
              fontWeight="400"
              paddingY="5px"
            >
              Select how you would want to republish
            </Text>
            <Tabs align="center">
              <TabList>
                <Tab>Online</Tab>
                <Tab>Offline</Tab>
                <Tab>Copy Link</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack>
                    {advancerdetails?.AdvancerObject &&
                      advancerdetails?.AdvancerObject.website && (
                        <Text
                          textAlign="center"
                          color="#666666"
                          fontStyle="normal"
                          fontWeight={400}
                          fontSize="16px"
                          lineHeight="24px"
                        >
                          Article will be republieshed on
                          <Text as="span" ml="2px" color="#4C2DB1">
                            {advancerdetails?.AdvancerObject.website}
                          </Text>
                        </Text>
                      )}
                    {advancerdetails?.AdvancerObject &&
                      !advancerdetails?.AdvancerObject.website && (
                        <Text
                          textAlign="center"
                          color="#666666"
                          fontStyle="normal"
                          fontWeight={400}
                          fontSize="16px"
                          lineHeight="24px"
                        >
                          You have not added a website yet. Please add a website
                          to republish this article.
                        </Text>
                      )}
                  </Stack>
                  <Center gap="8" mt="1em">
                    <Button
                      color="#999999"
                      _hover={{ bg: "#fff" }}
                      bg="#fff"
                      mr={3}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    {isLoading && (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                      />
                    )}
                    {advancerdetails?.AdvancerObject &&
                      advancerdetails?.AdvancerObject.website && (
                        <Button
                          type="submit"
                          isLoading={loading}
                          onClick={onRepublishArticle}
                          bg="#4C2DB1"
                          color="#fff"
                          size="lg"
                          loadingText="republishing..."
                          _hover={{ bg: "#4C2DB1", color: "#fff" }}
                        >
                          Republish
                        </Button>
                      )}
                    {advancerdetails?.AdvancerObject &&
                      !advancerdetails?.AdvancerObject.website && (
                        <Button
                          type="submit"
                          onClick={addWebsite}
                          bg="#4C2DB1"
                          color="#fff"
                          size="lg"
                          loadingText="republishing..."
                          _hover={{ bg: "#4C2DB1", color: "#fff" }}
                        >
                          Add Website
                        </Button>
                      )}
                  </Center>
                </TabPanel>
                <TabPanel>
                  <Text
                    textAlign="center"
                    color="#666666"
                    fontStyle="normal"
                    fontWeight={400}
                    fontSize="16px"
                    lineHeight="24px"
                  >
                    By selecting offline republishing you affirm that the
                    article will be used as stipulated under the CC licence it
                    was created.
                  </Text>
                  <Center gap="8" mt="1em">
                    <Button
                      color="#999999"
                      _hover={{ bg: "#fff" }}
                      bg="#fff"
                      mr={3}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    {isLoading && (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                      />
                    )}
                    {
                      <Button
                        type="submit"
                        isLoading={loading}
                        onClick={onOfflineRepublish}
                        bg="#4C2DB1"
                        color="#fff"
                        size="lg"
                        loadingText="Republishing..."
                        _hover={{ bg: "#4C2DB1", color: "#fff" }}
                      >
                        Republish
                      </Button>
                    }
                  </Center>
                </TabPanel>
                <TabPanel>
                  <Box display="flex" alignItems="center">
                    <Input
                      value={`https://advance.minorityafrica.org/article?id=${articleId}`}
                      isReadOnly
                    />
                    <Tooltip
                      label={hasCopied ? "Copied!" : "Copy Link"}
                      placement="top"
                    >
                      <IconButton
                        aria-label={hasCopied ? "Copied!" : "Copy Link"}
                        icon={
                          hasCopied ? (
                            <CheckCircleIcon color="green.500" />
                          ) : (
                            <CopyIcon />
                          )
                        }
                        onClick={onCopy}
                        ml={2}
                      />
                    </Tooltip>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Republish;
