import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserToken } from "../../../services/services";
import instance from "../../../services/api";
import axios, { AxiosError } from "axios";

interface Tag {
  name: string;
  tagId: string;
}
interface ArticleProps {
  ccLicenseName: string;
  tags: Tag[];
}
const initialState: ArticleProps = {
  ccLicenseName: "",
  tags: [],
};

export const getArticleTags = createAsyncThunk(
  "createArticle/getArticleTags",
  async (articleId: number, thunkApi) => {
    const url = "/articles/getArticleTags";
    return instance
      .get(url, {
        params: {
          articleId,
        },
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          return thunkApi.rejectWithValue(err);
        }
      });
  },
);

export const createArticleSlice = createSlice({
  name: "createArticle",
  initialState: initialState,
  reducers: {
    setCCLicenseName: (state, action) => {
      state.ccLicenseName = action.payload.ccLicenseName;
    },

    updateTags: (state, action) => {
      if (
        !state.tags.find((tag) => tag.name === action.payload.tag.name) &&
        state.tags.length < 3
      ) {
        state.tags.push(action.payload.tag);
      } else {
        state.tags.unshift(action.payload.tag);
        state.tags.pop();
      }
    },

    removeTag: (state, action) => {
      const foundIndex = state.tags.findIndex(
        (tag) => tag.name === action.payload.tag.name,
      );

      if (foundIndex !== -1) {
        state.tags.splice(foundIndex, 1);
      }
    },

    clearTags: (state, action) => {
      state.tags = [];
    },
  },
});

export { Tag };
export const { setCCLicenseName, updateTags, removeTag, clearTags } =
  createArticleSlice.actions;
export default createArticleSlice.reducer;
