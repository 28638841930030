import { createSlice } from "@reduxjs/toolkit";
import RepublishedTypes from "../../../model/republished.types";
import { getallArticles } from "./articleActions";

interface IArticleprops {
  loading: boolean;
  error: boolean;
  success: boolean;
  articlesData: RepublishedTypes;
}

const initialState: IArticleprops = {
  loading: false,
  error: false,
  success: false,
  articlesData: {
    currentPage: 1,
    totalArticles: 0,
    totalPages: 0,
    page: {
      page: 1,
      id: "",
    },
    list: [],
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 0,
    },
  },
};

export const allArticlesSlice = createSlice({
  name: "allarticleslist",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.articlesData = initialState.articlesData;
    },

    setArticlesPage: (state, action) => {
      state.articlesData.page = action.payload;
      state.articlesData.currentPage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getallArticles.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getallArticles.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.articlesData = {
        ...action.payload,
        page: action.meta.arg.page,
      };
    });

    builder.addCase(getallArticles.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.articlesData = initialState.articlesData;
    });
  },
});

export const { reset, setArticlesPage } = allArticlesSlice.actions;
export default allArticlesSlice.reducer;
