export function getMonthlist(date: Date) {
  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthList[date.getMonth()];
}

export function formatDate(date: Date) {
  return `${date.getDate()} ${getMonthlist(date)} ${date.getFullYear()}`;
}
