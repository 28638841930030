import {
  HStack,
  Stack,
  Tag,
  Text,
  IconButton,
  Img,
  Flex,
  Center,
} from "@chakra-ui/react";
import { RxDotsVertical } from "react-icons/rx";
import articleImg from "../../../assets/noarticle.png";

import { useNavigate, useParams } from "react-router-dom";
import RepublishedTypes from "../../../model/republished.types";
import Loader from "../../loader";
import { formatDate } from "../../../utils/date";

type ArticlesCardProps = {
  articleList: RepublishedTypes;
  isLoading: boolean;
};
export default function ArticlesCard({
  articleList,
  isLoading,
}: ArticlesCardProps) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <>
      {/* <Stack
        py="3"
        borderBottom="2px"
        borderBottomColor="#E6E6E6"
        w="100%"
        mb="2em"
      >
        <Text
          textAlign="left"
          color="#1A1A1A"
          fontSize="20px"
          lineHeight="28px"
          fontWeight="400"
        >
          Republished
        </Text>
      </Stack> */}
      {isLoading && <Loader />}
      {articleList?.list && articleList.list.length <= 0 && (
        <Flex justify="center" align="center" mt="10em">
          <Center>
            <Stack justify="center" align="center">
              <Img src={articleImg} h="5em" w="5em" />
              <Text
                textAlign="center"
                color="#666666"
                fontSize="20px"
                fontWeight="400"
                fontStyle="normal"
                lineHeight="28px"
              >
                Looks like you haven't reposted any articles yet.
                <br />
                <Text as="span" textAlign="center">
                  "Check publishers Articles" to begin
                </Text>
              </Text>
            </Stack>
          </Center>
        </Flex>
      )}
      {articleList?.list && articleList.list.length > 0 && (
        <Stack mt="3em">
          {articleList &&
            articleList.list.map((republishedArticles) => {
              return (
                <>
                  <Stack
                    w="100%"
                    py="3"
                    spacing="4"
                    boxShadow="lg"
                    rounded="lg"
                    p="2"
                    onClick={() => {
                      navigate(
                        `/dashboard/articlemetrics/${
                          articleList && republishedArticles._id
                        }/${params.routeId}`,
                      );
                    }}
                  >
                    <HStack
                      w="full"
                      justify="center"
                      align="center"
                      justifyContent="space-between"
                      p="auto"
                    >
                      <HStack
                        spacing="2"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="18px"
                      >
                        <Text color="#666666">
                          {articleList.list &&
                            articleList.list.indexOf(republishedArticles) + 1}
                        </Text>
                        <Tag color="#4C2DB1" bg="#EEEBFA" py="2" rounded="2xl">
                          {republishedArticles.category &&
                            republishedArticles.category.name}
                        </Tag>
                      </HStack>
                      <IconButton
                        aria-label="more"
                        icon={<RxDotsVertical />}
                        size="xs"
                        bg="#ffff"
                        _hover={{ bg: "#fff" }}
                      />
                    </HStack>
                    <Stack w="100%" align="center" justify="center" px="3">
                      <HStack w="100%">
                        <Img
                          src={
                            republishedArticles &&
                            republishedArticles.articleMedia[0]?.name
                          }
                          objectFit="cover"
                          h="2.5em"
                          w="5em"
                          borderRadius="lg"
                        />
                        <Text
                          fontStyle="normal"
                          noOfLines={2}
                          color="#1A1A1A"
                          fontWeight="400"
                          fontSize="14px"
                          lineHeight="18px"
                        >
                          {republishedArticles && republishedArticles.title}
                        </Text>
                      </HStack>
                      <HStack justifyContent="space-between" w="100%">
                        <Stack>
                          <Text
                            color="#666666"
                            fontWeight="700"
                            fontSize="10px"
                            lineHeight="18px"
                          >
                            Views
                          </Text>
                          <Text
                            color="#1A1A1A"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="18px"
                          >
                            {republishedArticles && republishedArticles.viewed}
                          </Text>
                        </Stack>
                        <Stack>
                          <Text
                            color="#666666"
                            fontWeight="700"
                            fontSize="10px"
                            lineHeight="18px"
                          >
                            Read
                          </Text>
                          <Text
                            color="#1A1A1A"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="18px"
                          >
                            {republishedArticles && republishedArticles.read}
                          </Text>
                        </Stack>
                        <Stack>
                          <Text
                            color="#666666"
                            fontWeight="700"
                            fontSize="10px"
                            lineHeight="18px"
                          >
                            Date Republished
                          </Text>
                          <Text
                            color="#1A1A1A"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="18px"
                          >
                            {formatDate(
                              new Date(
                                republishedArticles &&
                                  republishedArticles.createdAt,
                              ),
                            )}
                          </Text>
                        </Stack>
                      </HStack>
                    </Stack>
                  </Stack>
                </>
              );
            })}
        </Stack>
      )}
    </>
  );
}
