import axios, { AxiosError } from "axios";
import instance from "./api";
import { getUserToken } from "./services";

const getRepublishedArticles = async (id: string) => {
  const url = `advancerDashboard/getRepublishedArticles/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const becomePublisher = async (userId: string, organisation: string) => {
  const url = "/user/registerPublisher";
  const result = instance
    .post(
      url,
      {
        userId,
        organisation,
      },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const followPublisher = async (id: string, publisherUserId: string) => {
  const url = `/advancerDashboard/followPublisher/${id}`;
  const result = instance
    .post(
      url,
      { publisherUserId },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getCurrentPublisher = async (id: string) => {
  const url = `/publisherDashboard/getCurrentPublisherById/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const updateAdvancer = (
  userId: string,
  planId: string,
  company: string,
  website: string,
  applicationPassword: string,
  wordpressAdminName: string,
) => {
  const url = "/advancerDashboard/updateAdvancerProfile";
  const result = instance
    .put(
      url,
      {
        userId,
        planId,
        company,
        website,
        applicationPassword,
        wordpressAdminName,
      },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const rePublishArticle = async (id: string, articleId: string) => {
  const url = `/advancerDashboard/republishArticle/${id}`;
  const result = instance
    .post(
      url,
      {
        articleId,
      },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const offlineRepublish = async (id: string, articleId: string) => {
  const url = `/advancerDashboard/offLineRepublishing/${id}/${articleId}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const getFollowedPublishers = async (id: string) => {
  const url = `advancerDashboard/getFollowedPublishers/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const isPublisherFollowed = async (id: string, publisherId: string) => {
  const url = `advancerDashboard/isPublisherFollowed/${id}/${publisherId}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};
const getAdvancerDetails = async (id: string) => {
  const url = `/advancerDashboard/getAdvancerById/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const unFollowPublisher = (userid: string, publisherUserId: string) => {
  const url = `/advancerDashboard/unFollowPublisher/${userid}`;
  const result = instance
    .post(
      url,
      {
        publisherUserId,
      },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

export {
  getRepublishedArticles,
  becomePublisher,
  followPublisher,
  getCurrentPublisher,
  updateAdvancer,
  rePublishArticle,
  getFollowedPublishers,
  getAdvancerDetails,
  unFollowPublisher,
  offlineRepublish,
  isPublisherFollowed,
};
