/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Center,
  Flex,
  HStack,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import Loader from "../../../components/loader";
import { formatDate } from "../../../utils/date";
import Republish from "../../../components/advancer/republishArticle";
import { useNavigate, useParams } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import RepublishedTypes from "../../../model/republished.types";
import { AppDispatch, RootState } from "../../../state/store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getallArticles } from "../../../state/features/articles/articleActions";
import PaginationBtn from "../../../components/pagination/PaginationBtn";
import { setArticlesPage } from "../../../state/features/articles/articlesSlice";
import { useLocation } from "react-router-dom";

interface ArticleProps {
  articleList: RepublishedTypes;
}

function ArticleList({ articleList }: ArticleProps) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <>
      {articleList.list.map((article: any) => {
        return (
          <Stack>
            <Stack position="relative">
              {article.categories.map((category: any, index: any) => {
                return (
                  <Text
                    position="absolute"
                    right="0"
                    bottom="0"
                    color="#4C2DB1"
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="500"
                    bg="#ffffff"
                    p="1"
                    borderTopLeftRadius="10px"
                    borderBottomRightRadius="5px"
                  >
                    {category.name}
                  </Text>
                );
              })}
              <Img
                onClick={() =>
                  navigate(
                    `/dashboard/advancer/article/${params.routeId}/${
                      article && article._id
                    }`,
                  )
                }
                cursor="pointer"
                src={article?.articleMedia && article.articleMedia[0]?.name}
                borderRadius="lg"
                objectFit="cover"
                w="100%"
                h="12em"
              />
            </Stack>
            <HStack justifyContent="space-between" mt="1">
              <Text
                textAlign="right"
                color="#999999"
                fontStyle="normal"
                fontWeight={500}
                fontSize="10px"
                lineHeight="16px"
              >
                {formatDate(new Date(article.createdAt))}
              </Text>
            </HStack>
            <Text
              noOfLines={2}
              mt="1"
              color="#1A1A1A"
              fontSize="14px"
              fontWeight="400"
              fontStyle="normal"
              lineHeight="20px"
            >
              {article && article.title}
            </Text>
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                onClick={() =>
                  navigate(
                    `/dashboard/advancer/article/${params.routeId}/${
                      article && article._id
                    }`,
                  )
                }
                cursor="pointer"
                color="#4C2DB1"
                fontStyle="normal"
                fontWeight={400}
                fontSize="12px"
                lineHeight="18px"
              >
                Read
              </Text>

              <Republish {...article} />
            </Flex>
          </Stack>
        );
      })}
    </>
  );
}

const ViewAllArticles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const tagId = state?.tagId;
  const name = state?.name;
  const { loading, error, articlesData } = useSelector(
    (state: RootState) => state.allArticles,
  );

  const page = articlesData?.page?.page;

  useEffect(() => {
    dispatch(getallArticles({ page, tagId, approved: "approved" }));
    if (error) {
      console.log(error);
    }
  }, [dispatch, page, error, tagId]);

  const onPageChange = (page: number) => {
    dispatch(setArticlesPage(page));
    dispatch(getallArticles({ page, tagId, approved: "approved" }));
  };

  return (
    <>
      <Stack px="2em">
        <Heading
          color="#1A1A1A"
          fontWeight={400}
          fontSize="32px"
          lineHeight="32px"
          fontStyle="normal"
        >
          Dashboard
        </Heading>
      </Stack>
      <Stack mt="3em" px="2em" w="100%">
        <Flex
          w="100%"
          justifyContent="space-between"
          borderBottom="1px"
          borderBottomColor="#F2F2F2"
          fontWeight="400"
          fontStyle="normal"
        >
          <Text color="#1A1A1A" fontSize="16px" lineHeight="24px">
            {name ? `Articles on ${name}` : "Recent Post from Publishers"}
          </Text>
          <Link
            onClick={() => {
              navigate("/dashboard/advancer-dashboard/1");
            }}
          >
            <Text color="#E7584B" fontSize="14px" lineHeight="20px">
              Hide
            </Text>
          </Link>
        </Flex>
      </Stack>
      {loading ? (
        <Loader />
      ) : articlesData?.list && articlesData?.list.length <= 0 ? (
        <Center>
          <Stack align="center">
            <CgFileDocument fontSize="6em" color="#CCCCCC" />
            <Text
              textAlign="center"
              color="#666666"
              fontSize="20px"
              fontWeight="400"
              fontStyle="normal"
              lineHeight="28px"
            >
              {name ? `No articles on ${name}` : "No recent articles yet."}
            </Text>
          </Stack>
        </Center>
      ) : (
        <Stack mt="1em" ml={{ base: "1", md: "1", lg: " 2em" }}>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 8 }}>
            <ArticleList articleList={articlesData} />
          </SimpleGrid>
          <Stack justify="center" align="center" w="100%">
            <PaginationBtn
              currentPage={articlesData.pagination.page}
              totalPages={articlesData.pagination.totalPages}
              onPageChange={onPageChange}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ViewAllArticles;
