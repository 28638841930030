import { configureStore } from "@reduxjs/toolkit";
import publishersSlice from "../features/publishers/publishersSlice";
import advancersSlice from "../features/advancers/advancersSlice";
import articlesSlice from "../features/publishers-articles/articlesSlice";
import draftSlice from "../features/publisher-drafts/publisherDraftsSlice";
import allArticlesSlice from "../features/articles/articlesSlice";
import createArticleSlice from "../features/articles/createArticleSlice";
import republishedSlice from "../features/republished-articles/republishedSlice";
import searchSlice from "../features/search/searchSlice";
import metricsSlice from "../features/metrics/metricsSlice";
import articleReducer from "../reducers/articleReducer";

export const store = configureStore({
  reducer: {
    publishers: publishersSlice,
    advancerlist: advancersSlice,
    articleslist: articlesSlice,
    draftsList: draftSlice,
    allArticles: allArticlesSlice,
    createArticle: createArticleSlice,
    republishedArticles: republishedSlice,
    search: searchSlice,
    metrics: metricsSlice,
    articleReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
