import { Stack, IconButton, Box } from "@chakra-ui/react";
import { TbPencil } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

export default function FloatingButton() {
  const navigate = useNavigate();
  return (
    <Stack
      position="relative"
      display={{ base: "block", md: "none", lg: "none" }}
    >
      <Box position="fixed" bottom="9" right="6">
        <IconButton
          onClick={() => {
            navigate("/dashboard/create-article/2");
          }}
          borderRadius="15px"
          bg="#E23525"
          aria-label="create article"
          size="lg"
          icon={<TbPencil color="#fff" />}
        />
      </Box>
    </Stack>
  );
}
