import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthContextProvider } from "./context/authContext";
import { ActiveLinksContextProvider } from "./hooks/switchContext";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import { store } from "./state/store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <AuthContextProvider>
        <ActiveLinksContextProvider>
          <ChakraProvider>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_CLIENT_ID as string}
            >
              <BrowserRouter>
                <Provider store={store}>
                  <App />
                </Provider>
              </BrowserRouter>
            </GoogleOAuthProvider>
          </ChakraProvider>
        </ActiveLinksContextProvider>
      </AuthContextProvider>
    </RecoilRoot>
  </React.StrictMode>,
);
