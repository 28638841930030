import { Avatar, Stack, Text, AvatarBadge } from "@chakra-ui/react";
import Loader from "../loader";
import { Link, useParams } from "react-router-dom";
import useTopPublishers from "../../hooks/useTopPublishers";

type PublisherProps = {
  _id: string;
  totalArticles: string;
  publisherProfile: {
    _id: string;
    name: string;
    surname: string;
    email: string;
    countryCode: string;
    isAccountDeactivated: boolean;
    profilePicture: string;
  };
};
const TopPublishers = () => {
  const params = useParams();
  const { publishers, isLoading } = useTopPublishers();
  return (
    <>
      <Stack
        w="100%"
        maxW="15em"
        border="1px"
        borderColor="#F2F2F2"
        rounded="lg"
        spacing="4"
        flexDirection="column"
      >
        <Stack align="flex-start">
          <Text fontSize="1em" py="0.6em" px="3" textAlign="right">
            Top Publishers
          </Text>
        </Stack>
        {isLoading && <Loader />}
        {publishers && publishers.length <= 0 && (
          <Stack align="center" justify="center">
            <Text
              textAlign="center"
              mt="5em"
              color="#666666"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
            >
              No top publishers yet
            </Text>
          </Stack>
        )}
        {publishers && publishers.length > 0 && (
          <>
            {publishers.map((publisher: PublisherProps, index: number) => {
              return (
                <Stack
                  align="flex-start"
                  gap="3"
                  alignItems="center"
                  w="100%"
                  flexDirection="row"
                  maxW="15em"
                >
                  {publisher?.publisherProfile &&
                  publisher.publisherProfile.profilePicture ? (
                    <Avatar
                      size="sm"
                      src={
                        publisher?.publisherProfile &&
                        publisher.publisherProfile.profilePicture
                      }
                      ml="3"
                    >
                      <AvatarBadge boxSize="1.25em" bg="green.500" />
                    </Avatar>
                  ) : (
                    <Avatar
                      size="sm"
                      name={
                        publisher?.publisherProfile &&
                        publisher.publisherProfile.name
                      }
                      ml="3"
                    >
                      <AvatarBadge boxSize="1.25em" bg="green.500" />
                    </Avatar>
                  )}

                  <Link
                    to={`/dashboard/publisher-profile/${
                      publisher?.publisherProfile &&
                      publisher.publisherProfile._id
                    }/${
                      publisher?.publisherProfile &&
                      publisher.publisherProfile.name
                    }/${
                      publisher.publisherProfile &&
                      publisher.publisherProfile.surname
                    }/${params.routeId}`}
                  >
                    <Text
                      color="#1A1A1A"
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="20px"
                    >
                      {publisher?.publisherProfile &&
                        publisher.publisherProfile.name}{" "}
                      {publisher.publisherProfile &&
                        publisher.publisherProfile.surname}
                    </Text>
                  </Link>
                </Stack>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};

export default TopPublishers;
