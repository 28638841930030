import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  IconButton,
  Text,
  Center,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import useAuth from "../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store/store";
import { deleteDraftArticle } from "../../../state/features/publisher-drafts/publisherDraftsActions";
import { getAllPublisherArticles } from "../../../state/features/publishers-articles/articleActions";

type deleteArticleProps = {
  articleId: string;
};

const Deletarticle = ({ articleId }: deleteArticleProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch<AppDispatch>();
  const { loggedUser } = useAuth();
  const { articlesData } = useSelector(
    (state: RootState) => state.articleslist,
  );

  const id = loggedUser?.userId as string;
  const page = articlesData.page.page;
  const onDelete = async () => {
    dispatch(deleteDraftArticle(articleId));
    if (id) {
      dispatch(getAllPublisherArticles({ page, id, approved: undefined }));
    }
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        bg="#fff"
        aria-label="edit"
        size="lg"
        _hover={{ bg: "#fff", color: "#E23525" }}
        icon={<RiDeleteBinLine color="#F3ABA5" />}
      />
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent rounded="lg">
          <ModalCloseButton />
          <ModalBody p="3em">
            <Stack>
              <Heading
                color="#E23525"
                textAlign="center"
                fontSize="32px"
                fontWeight={400}
                lineHeight="32px"
              >
                Delete Article
              </Heading>
              <Text
                textAlign="center"
                color="#666666"
                fontStyle="normal"
                fontWeight={400}
                fontSize="16px"
                lineHeight="24px"
              >
                Are you sure you want to delete this article
              </Text>
            </Stack>

            <Center gap="8" mt="1em">
              <Button
                color="#999999"
                _hover={{ bg: "#fff" }}
                bg="#fff"
                mr={3}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                bg="#E23525"
                color="#fff"
                size="lg"
                onClick={onDelete}
                _hover={{ bg: "#E23525", color: "#fff" }}
              >
                Delete
              </Button>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Deletarticle;
