/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACTION_TYPES } from "../actions/types";
const initialState = {
  article: {},
  articleLoading: false,
  visitorArticleId: "",
};

export default function reducerCase(state = initialState, action: any) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_ARTICLE_LOADING:
      return {
        ...state,
        articleLoading: action.payload,
      };

    case ACTION_TYPES.GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
      };

    case ACTION_TYPES.SET_VISITOR_ARTICLE_ID:
      return {
        ...state,
        visitorArticleId: action.payload,
      };
    default:
      return state;
  }
}
