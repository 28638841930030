import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "../components/loader";
import { Flex } from "@chakra-ui/react";
import Articles from "../pages/publisher/sub-pages/articles/Articles";
import ViewAllArticles from "../pages/advancer/recentArticles/recent-published";
import TopPublishers from "../components/dashboard/top-publishers";

const ArticlePreview = lazy(
  () => import("../pages/publisher/sub-pages/preview-article/ArticlePreview"),
);
const Privacy = lazy(() => import("../pages/Privacy-and-Policy/Privacy"));
const TermsAndConditions = lazy(
  () => import("../pages/terms-and-conditions/TermsAndConditions"),
);

const ArticleInfomation = lazy(
  () => import("../pages/advancer/article-info/ArticleInfo"),
);
const EditDrafts = lazy(
  () => import("../pages/publisher/sub-pages/edit-draft/edit-draft"),
);
const ActiveAdminList = lazy(
  () => import("../components/dashboard/admin/ActiveAdmins"),
);
const NewPublishers = lazy(
  () => import("../components/advancer/newPublishers"),
);
const About = lazy(() => import("../pages/About/About"));
const Contact = lazy(() => import("../pages/Contact/Contact"));
const Following = lazy(() => import("../pages/advancer/following/following"));
const Republished = lazy(
  () => import("../pages/advancer/republished/republished"),
);
const CreateArticle = lazy(
  () => import("../pages/publisher/sub-pages/Create-Articles/create-article"),
);
const AdvancerDashboard = lazy(
  () => import("../pages/advancer/Advancer-dashboard/AdvancerDashboard"),
);

const PublisherProfile = lazy(
  () => import("../pages/advancer/advancer-profile/PublisherProfile"),
);

const AdvancerProfile = lazy(
  () => import("../pages/advancer/advancer-profile/AdvancerProfile"),
);

const ViewDrafts = lazy(
  () => import("../pages/publisher/sub-pages/Drafts/ViewDrafts"),
);

const ArticlesMetrics = lazy(
  () => import("../pages/publisher/sub-pages/articleMetrics/articleMetrics"),
);
const EditArticleDraft = lazy(
  () => import("../pages/publisher/sub-pages/edit-article/EditArticle"),
);
const Newpassword = lazy(() => import("../pages/newpassword/newpassword"));
const PubliserDashboard = lazy(
  () => import("../pages/publisher/sub-pages/dashboard/publiserDashboard"),
);
const Reset = lazy(() => import("../pages/resetPassword/reset"));
const Signin = lazy(() => import("../pages/signin/Signin"));
const Location = lazy(() => import("../pages/signin/Location"));
const Signup = lazy(() => import("../pages/Signup/Signup"));
const Home = lazy(() => import("../pages/Home/Home"));
const Article = lazy(() => import("../pages/Home/Article"));
const Verification = lazy(() => import("../pages/Verification/verification"));
const Admin = lazy(() => import("../pages/admin/Admin"));
const DashboardHome = lazy(() => import("../pages/admin/sub-pages/Home/Home"));
const Statistics = lazy(
  () => import("../pages/admin/sub-pages/Stats/statistics"),
);
const Publisher = lazy(
  () => import("../pages/admin/sub-pages/publishers/Publisher"),
);
const Advancers = lazy(
  () => import("../pages/admin/sub-pages/advancers/advancers"),
);
const Metrics = lazy(() => import("../pages/admin/sub-pages/metrics/metrics"));
const Settings = lazy(
  () => import("../pages/admin/sub-pages/settings/settings"),
);

const ApproveArticles = lazy(() => import("../pages/admin/ApproveArticles"));
const ReadArticle = lazy(
  () => import("../pages/advancer/read-article/read-article"),
);

const Publishers = lazy(() => import("../pages/publisher/Publishers"));
const Mainroutes = () => {
  return (
    <>
      <Suspense
        fallback={
          <Flex justify="center" align="center" h="100vh">
            <Loader />
          </Flex>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/article" element={<Article />} />
          <Route path="signin" element={<Signin />} />
          <Route path="location" element={<Location />} />
          <Route path="register" element={<Signup />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="passwordreset" element={<Reset />} />
          <Route path="verify" element={<Verification />} />
          <Route path="newpassword" element={<Newpassword />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy" element={<Privacy />} />

          <Route path="/" element={<Admin />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="/dashboardhome" element={<DashboardHome />}>
              <Route
                path="/dashboardhome/newpublishers/:routeId"
                element={<NewPublishers />}
              />
              <Route
                path="top-publishers/:routeId"
                element={<TopPublishers />}
              />
              <Route
                path="activeadmins/:routeId"
                element={<ActiveAdminList />}
              />
            </Route>
            <Route
              path="dashboard/analysis/:routeId"
              element={<Statistics />}
            />
            <Route
              path="dashboard/publishers/:routeId"
              element={<Publisher />}
            />
            <Route
              path="dashboard/advancers/:routeId"
              element={<Advancers />}
            />
            <Route path="dashboard/metrics/:routeId" element={<Metrics />} />
            <Route path="dashboard/settings/:routeId" element={<Settings />} />
            <Route
              path="publisher-dashboard/:routeId"
              element={<PubliserDashboard />}
            />
            <Route
              path="dashboard/publisher/:articles/:routeId"
              element={<Articles />}
            />
            <Route path="dashboard/settings/:routeId" element={<Settings />} />
            <Route
              path="dashboard/approve-articles/:routeId"
              element={<ApproveArticles />}
            />

            <Route
              path="dashboard/create-article/:routeId"
              element={<CreateArticle />}
            />
            <Route
              path="dashboard/articlemetrics/:articleId/:routeId"
              element={<ArticlesMetrics />}
            />
            <Route
              path="dashboard/edit-article/:articleId/:routeId"
              element={<EditArticleDraft />}
            />
            <Route
              path="dashboard/edit-draft/:articleId/:routeId"
              element={<EditDrafts />}
            />
            <Route path="dashboard/drafts/:routeId" element={<ViewDrafts />} />
            <Route
              path="dashboard/publisher-profile/:publisherId/:name/:surname/:routeId"
              element={<PublisherProfile />}
            />

            <Route
              path="dashboard/advancer-profile/:advancerId/:name/:surname/:routeId"
              element={<AdvancerProfile />}
            />
            <Route
              path="dashboard/advancer-dashboard/:routeId"
              element={<AdvancerDashboard />}
            />
            <Route
              path="dashboard/advancer/following/:routeId"
              element={<Following />}
            />
            <Route
              path="dashboard/advancer/republished/:routeId"
              element={<Republished />}
            />
            <Route
              path="dashboard/advancer/article-info/:articleId/:publisherId/:name/:surname/:routeId"
              element={<ArticleInfomation />}
            />
            <Route
              path="dashboard/advancer/all-articles/:articleId/:routeId"
              element={<ViewAllArticles />}
            />
            <Route
              path="dashboard/advancer/all-articles/:routeId"
              element={<ViewAllArticles />}
            />
            <Route
              path="dashboard/advancer/article/:routeId/:articleId"
              element={<ReadArticle />}
            />
            <Route
              path="dashboard/view-publishers/:routeId"
              element={<Publishers />}
            />
            <Route
              path="dashboard/publisher/preview-article/:routeId/:articleId"
              element={<ArticlePreview />}
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default Mainroutes;
