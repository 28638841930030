import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import instance from "../../../services/api";
import { getUserToken } from "../../../services/services";

const getPublisherDrafts = createAsyncThunk(
  "publisherDrafts/getPublisherDrafts",
  async ({ id, page }: { id: string; page: number }, thunkApi) => {
    const limit = 10;
    const url = `publisherDashboard/getDraftArticles/${id}`;
    return instance
      .get(url, {
        params: {
          page: page,
          limit: limit,
        },
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          return thunkApi.rejectWithValue(err);
        }
      });
  },
);

const deleteDraftArticle = createAsyncThunk(
  "publisherDrafts/deleDraftArticle",
  async (id: string, thunkApi) => {
    const url = `/publisherDashboard/deleteArticle/${id}`;
    return instance
      .delete(url, {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          return thunkApi.rejectWithValue(err);
        }
      });
  },
);

export { getPublisherDrafts, deleteDraftArticle };
