import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import instance from "../../../services/api";
import { getUserToken } from "../../../services/services";

export const getAllPublisherArticles = createAsyncThunk(
  "publisher/getAllPublisherArticles",
  async (
    {
      page,
      id,
      approved,
    }: { page: number; id: string; approved: string | undefined },
    thunkApi,
  ) => {
    if (!page) {
      return;
    }
    const limit = 10;
    const url = `publisherDashboard/getPublisherArticles/${id}`;
    return instance
      .get(url, {
        params: {
          page: page,
          limit: limit,
          approved: approved,
        },
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          return thunkApi.rejectWithValue(err);
        }
      });
  },
);
