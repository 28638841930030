/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SetStateAction,
  createContext,
  useMemo,
  useRef,
  useState,
} from "react";

export type activeLinks = {
  activeRole: {
    active: boolean;
    admin: boolean;
    publisher: boolean;
    advancer: boolean;
  };
};

type activeLinksContextType = {
  activeLinks: activeLinks | null;
  setActiveLinks: React.Dispatch<SetStateAction<activeLinks | null>>;
};

type roleProps = {
  children: React.ReactNode;
};

const ActiveLinksContext = createContext<activeLinksContextType>(
  {} as activeLinksContextType,
);

function ActiveLinksContextProvider({ children }: roleProps) {
  const [activeLinks, setActiveLinks] = useState<activeLinks | null>(null);
  const prevRoleRef = useRef(activeLinks);
  prevRoleRef.current = activeLinks;
  const activeLinksValue = useMemo(
    () => ({ activeLinks, setActiveLinks }),
    [activeLinks, setActiveLinks],
  );
  return (
    <ActiveLinksContext.Provider value={activeLinksValue}>
      {children}
    </ActiveLinksContext.Provider>
  );
}

export { ActiveLinksContextProvider, ActiveLinksContext };
