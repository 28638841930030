import { createSlice } from "@reduxjs/toolkit";
import PublishersLists from "../../../model/publishers";
import { getAllPublishers } from "./publisherActions";

interface publishersProps {
  loading: boolean;
  error: boolean;
  success: boolean;
  publishersData: PublishersLists;
}

const initialState: publishersProps = {
  loading: false,
  error: false,
  success: false,
  publishersData: {
    totalPublishers: "",
    totalPages: 0,
    page: 1,
    list: [],
    hasNextPage: true,
    totalApproved: 0,
  },
};

export const publishersSlice = createSlice({
  name: "publisher",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.error = false;
      state.success = false;
      state.publishersData = initialState.publishersData;
    },
    setCurrentPage: (state, action) => {
      state.publishersData.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPublishers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllPublishers.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.publishersData = {
        ...action.payload,
        page: action.meta.arg.page,
      };
    });

    builder.addCase(getAllPublishers.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.publishersData = initialState.publishersData;
    });
  },
});
export const { reset, setCurrentPage } = publishersSlice.actions;
export default publishersSlice.reducer;
