import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import instance from "../../../services/api";
import { getUserToken } from "../../../services/services";

export const getallArticles = createAsyncThunk(
  "articles/getallArticles",
  async (
    {
      page,
      tagId,
      approved,
    }: { tagId?: string; page: number; approved?: string },
    thunkApi,
  ) => {
    const limit = 10;
    let url = "/articles/getArticles";

    if (tagId && approved) {
      url = `/articles/getArticles?tagId=${tagId}&approved=${approved}`;
    } else if (approved && !tagId) {
      url = `/articles/getArticles?approved=${approved}`;
    } else if (tagId && !approved) {
      url = `/articles/getArticles?tagId=${tagId}`;
    }

    return instance
      .get(url, {
        params: {
          page: page,
          limit: limit,
        },
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          return thunkApi.rejectWithValue(err);
        }
      });
  },
);

export const changeArticleStatus = createAsyncThunk(
  "articles/changeArticleStatus",
  async (
    {
      articleId,
      status,
      feedback,
    }: { articleId: string; status: string; feedback?: string },
    thunkApi,
  ) => {
    const url = "/articles/changeStatus";

    try {
      const response = await instance.patch(
        url,
        {
          status,
          articleId,
          feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        return thunkApi.rejectWithValue(err);
      }
      throw error;
    }
  },
);
