import { decodeToken } from "react-jwt";
import { authUsers } from "../context/authContext";

interface AuthData {
  phoneNumber: string;
  countryCode: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
  profilePic: Array<string>;
  role: {
    admin: boolean;
    publisher: boolean;
    advancer: boolean;
  };
}

export default function convertTokenToUser(token: string): authUsers | null {
  const authToken = decodeToken<AuthData>(token);
  if (!authToken) return null;
  const user: authUsers = {
    phoneNumber: authToken.phoneNumber,
    countryCode: authToken.countryCode,
    email: authToken.email,
    firstName: authToken.firstName,
    lastName: authToken.lastName,
    userId: authToken.userId,
    profilePic: authToken.profilePic,
    role: {
      admin: authToken.role.admin,
      publisher: authToken.role.publisher,
      advancer: authToken.role.advancer,
    },
  };
  return user;
}
