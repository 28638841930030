/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from "axios";

import instance from "./api";
import { Tag } from "../state/features/articles/createArticleSlice";
const getUserToken = () => {
  const token = localStorage.getItem("token") as string;
  if (token) {
    return token;
  }
};

const getAdvancers = async () => {
  const url = "/adminDashboard/getAdvancers";
  const advancers = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return advancers;
};

const getPublishers = async () => {
  const url = "/adminDashboard/getPublishers";
  const publishers = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return publishers;
};

const getPublisher = async (id: string) => {
  const url = `/publisherDashboard/getPublisher/${id}`;
  const publisher = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return publisher;
};

const getAdvancer = async (id: string) => {
  const url = `/advancerDashboard/getAdvancer/${id}`;
  const publisher = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return publisher;
};

const getLoggedUser = async (id: string) => {
  const url = `/adminDashboard/getAdminProfile/${id}`;
  const publishers = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return publishers;
};

const getAdmins = async () => {
  const url = "/adminDashboard/getAdmins";
  const admins = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return admins;
};

const upDatePassword = async (
  id: string,
  currentPassword: string,
  newPassword: string,
) => {
  const url = `/user/updatePassword/${id}`;
  const result = instance
    .put(
      url,
      { currentPassword, newPassword },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const uploadImage = async (id: string, container: string, file: File) => {
  const url = "/user/uploadMedia";

  const result = instance
    .post(
      url,
      { id, container, file },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
          "Content-Type": "multipart/form-data",
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const deleteImage = async (container: string, filename: string, id: string) => {
  const url = `user/deleteUploadedMedia/${container}/${filename}/${id}`;
  const result = instance
    .delete(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};
const updateAdminprofile = async (
  id: string,
  name: string,
  surname: string,
  email: string,
  location: string,
  phoneNumber: string,
) => {
  const url = `/user/updateProfile/${id}`;
  const result = instance
    .put(
      url,
      { name, email, surname, location, phoneNumber },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getTopAdvancers = async () => {
  const url = "/metrics/getWeeklyTopAdvancers";
  const advancers = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return advancers;
};

const getTopPublishers = async () => {
  const url = "/metrics/getWeeklyTopPublishers";
  const publishers = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data.topPublishers;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return publishers;
};

const getNoOfArticles = async () => {
  const result = instance
    .get("/articles/getArticles", {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        const articlesCount = response.data.totalArticles;
        return articlesCount;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getNoOfAdimns = async () => {
  const result = instance
    .get("/adminDashboard/getAdmins", {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        const adminsCount = response.data;
        return adminsCount;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const makeSuperAdmin = async (id: string) => {
  const url = `/adminDashboard/makeSuperAdmin/${id}`;
  const result = instance
    .post(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const deactiveAdmin = async (id: string, password: string) => {
  const url = `/adminDashboard/deactivateAdminAccount/${id}`;
  const result = instance
    .put(
      url,
      { password },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
      return;
    });

  return result;
};

const uploadProfilePicture = async (id: string, image: string) => {
  const url = "/user/uploadProfilePic";
  const result = instance
    .post(
      url,
      { id, image },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log("Here is the  err" + err);
      }
    });

  return result;
};
const approvePublisher = async (id: string) => {
  const url = `/adminDashboard/approvePublisher/${id}`;
  const result = instance
    .post(
      url,
      { id },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const subscribeNotifications = async (id: string) => {
  const url = `/user/subscribeNotifications/${id}`;
  const result = instance
    .post(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const revokePublishers = async (id: string) => {
  const url = `/adminDashboard/revokePublisher/${id}`;
  const result = instance
    .post(
      url,
      { id },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};
const createArticle = async (
  userId: string,
  tags: Tag[],
  title: string,
  body: string,
  state: string,
  ccLicenseName: string,
) => {
  const result = instance
    .post(
      "/articles/addArticle",
      {
        userId,
        categories: tags,
        title,
        body,
        state,
        ccLicenseName,
      },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const getPublisherArticle = (id: string) => {
  const url = `publisherDashboard/getPublisherArticles/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getArticleTags = () => {
  const result = instance
    .get("/articles/getArticleCategories", {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const deleteArticle = async (id: string) => {
  const url = `/publisherDashboard/deleteArticle/${id}`;
  const result = instance
    .delete(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const uploadMedia = (itemID: string, itemName: string, fileName: any) => {
  const url = "/user/uploadMedia";
  const result = instance
    .post(
      url,
      {
        id: itemID,
        container: itemName,
        file: fileName,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getArticleDrafts = (id: string) => {
  const url = `publisherDashboard/getDraftArticles/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getArticles = (url: string) => {
  //const url = "/articles/getArticles";
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const addViewedArticled = (articleId: string) => {
  const url = "/metrics/addViewedArticle";
  const result = instance
    .post(
      url,
      { articleId },
      { headers: { Authorization: ` Bearer ${getUserToken()} ` } },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const addReadArticle = (articleId: string) => {
  const url = "/metrics/addReadArticle";
  const result = instance
    .post(
      url,
      { articleId },
      { headers: { Authorization: `Bearer ${getUserToken()}` } },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const upDateArticle = (
  id: string,
  tags: Tag[],
  title: string,
  body: string,
  state: string,
  ccLicenseName: string,
) => {
  const url = `/publisherDashboard/editArticle/${id}`;
  const result = instance
    .put(
      url,
      {
        categories: tags,
        title,
        body,
        state,
        ccLicenseName,
      },
      {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      },
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getRecentArticle = (id: string) => {
  const url = `/publisherDashboard/getRecentPublisherArticles/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getTopArticles = (id: string) => {
  const url = `/publisherDashboard/getTopPublisherArticles/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const deleteToken = (email: string) => {
  const url = "/user/deleteAccount";
  const result = instance
    .delete(url, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
      data: {
        email: email,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const deleteUser = (id: string) => {
  const url = `/user/confirmDeleteAccount?token=${id}`;
  const result = instance
    .delete(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getArticlesPosted = (id: string) => {
  const url = `/publisherDashboard/getPublishedArticlesNo/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getFollowers = (id: string) => {
  const url = `/publisherDashboard/getNoFollowers/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const senContactUsEmail = async (
  Fistname: string,
  Lastname: string,
  email: string,
  Content: string,
) => {
  const url = "/landingPage/sendContactUsEmail";
  const result = instance
    .post(url, { Fistname, Lastname, email, Content })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });

  return result;
};

const getLatestAticles = async () => {
  const url = "/landingPage/getTopThreeArticles";
  const publishers = instance
    .get(url)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return publishers;
};

const userSubscription = async (email: string) => {
  const url = "/landingPage/subscribeToNewsLetter";
  const result = instance
    .post(url, { email })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

const getMontlyReads = async () => {
  const url = "/landingPage/getNoMonthlyReads";
  const results = instance
    .get(url)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return results;
};

const getNoAdvancers = async () => {
  const url = "/landingPage/getNoAdvancers";
  const results = instance
    .get(url)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return results;
};

const getNoPublishers = async () => {
  const url = "/landingPage/getNoPublishers";
  const results = instance
    .get(url)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return results;
};

const getNoArticles = async () => {
  const url = "/landingPage/getNoArticles";
  const results = instance
    .get(url)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return results;
};

const getUSerProfile = async (id: string) => {
  const url = `/user/getUserById/${id}`;
  const results = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return results;
};

const getRepublishedArticleNo = async (id: string) => {
  const url = `/publisherDashboard/getRepublishedArticlesNo/${id}`;
  const result = instance
    .get(url, {
      headers: {
        Authorization: ` Bearer ${getUserToken()} `,
      },
    })
    .then((response) => {
      if (response.data) {
        const adminsCount = response.data;
        return adminsCount;
      }
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const err = error.response.data;
        console.log(err);
      }
    });
  return result;
};

export {
  getPublishers,
  getAdvancers,
  getAdmins,
  upDatePassword,
  uploadImage,
  getTopAdvancers,
  getTopPublishers,
  getNoOfArticles,
  getNoOfAdimns,
  updateAdminprofile,
  makeSuperAdmin,
  deactiveAdmin,
  approvePublisher,
  uploadProfilePicture,
  subscribeNotifications,
  revokePublishers,
  createArticle,
  getPublisherArticle,
  getArticleTags,
  deleteArticle,
  uploadMedia,
  getArticleDrafts,
  getArticles,
  addViewedArticled,
  addReadArticle,
  upDateArticle,
  getRecentArticle,
  getTopArticles,
  deleteToken,
  deleteUser,
  getFollowers,
  getArticlesPosted,
  getUserToken,
  getLoggedUser,
  senContactUsEmail,
  getLatestAticles,
  userSubscription,
  getNoArticles,
  getNoPublishers,
  getMontlyReads,
  getNoAdvancers,
  deleteImage,
  getUSerProfile,
  getRepublishedArticleNo,
  getPublisher,
  getAdvancer,
};
