import { useMediaQuery } from "@chakra-ui/react";
import { getAllPublisherArticles } from "../../../../state/features/publishers-articles/articleActions";
import FloatingButton from "../../../../components/publisher/create-article-button";
import ArticlesList from "../../../../components/publisher/Articles/ArticlesList";
import ArticlesCard from "../../../../components/publisher/Articles/articles-card";
import ArticlesFilter from "../../../../components/publisher/Articles/articlesFilter";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../state/store/store";
import { useEffect } from "react";

const Articles = () => {
  const { loggedUser } = useAuth();
  const [isMobile] = useMediaQuery("(max-width: 778px)");
  const { loading, articlesData } = useSelector(
    (state: RootState) => state.articleslist,
  );

  const id = loggedUser?.userId as string;
  const page = articlesData.page.page;
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) {
      dispatch(getAllPublisherArticles({ page, id, approved: "" }));
    }
  }, [page, id, dispatch]);

  return (
    <>
      <ArticlesFilter label={"Articles"} />
      {isMobile ? (
        <ArticlesCard articleList={articlesData} isLoading={loading} />
      ) : (
        <ArticlesList publisherArticles={articlesData} isLoading={loading} />
      )}
      <FloatingButton />
    </>
  );
};

export default Articles;
