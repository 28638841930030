import { useContext, useLayoutEffect, useState } from "react";
import { AuthContext, authUsers } from "../context/authContext";
import convertTokenToUser from "../utils/jwt";

const useAuth = () => {
  const tokenKey = "token";
  const [isLogged, setIslogged] = useState<boolean>(false);
  const [loggedUser, setloggedUser] = useState<authUsers | null>(null);
  const { user, setUser } = useContext(AuthContext);

  const singIn = function LoginUser(token: string) {
    if (isLogged && loggedUser) return;

    try {
      localStorage.setItem(tokenKey, token);
      const decodedUser = convertTokenToUser(token);

      if (decodedUser) {
        setloggedUser(user);
        setIslogged(true);
        setUser(decodedUser);
      }
    } catch (error) {
      setIslogged(false);
    }
  };

  const getTokne = () => {
    return localStorage.getItem(tokenKey);
  };

  const singOut = function LogoutUser() {
    localStorage.clear();
    setIslogged(false);
    setUser(null);
  };

  const isOnDashboard = () => {
    return window.location.href.indexOf("dashboard") > -1;
  };

  useLayoutEffect(() => {
    const token = getTokne();
    if (!isOnDashboard()) return;

    if (!token || token === null) {
      window.location.href = "/";
    } else {
      singIn(token as string);
    }
  });
  return { isLogged, singIn, singOut, getTokne, loggedUser };
};

export default useAuth;
