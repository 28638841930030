import {
  Box,
  // Button,
  Heading,
  HStack,
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
} from "@chakra-ui/react";
// import { IoFilterSharp, IoFunnelOutline } from "react-icons/io5";

interface statusProps {
  label: string;
}

const ArticlesFilter = (props: statusProps) => {
  const { label } = props;
  return (
    <Box
      w="100%"
      py="1"
      borderBottom={{ base: "2px", md: "0" }}
      borderBottomColor={{ base: "#E6E6E6", md: "none" }}
    >
      <HStack
        justifyContent="space-between"
        maxWidth="-moz-fit-content"
        px="1em"
      >
        <Heading
          color="#1A1A1A"
          fontSize={{ base: "20px", md: "32px", lg: " 32px" }}
          fontWeight="400"
          lineHeight={{ base: "28px", md: "32px", lg: "32px" }}
          fontStyle="normal"
        >
          {label}
        </Heading>
        {/* <HStack flexDirection="row" gap="2" alignItems="center">
          <Menu>
            <MenuButton
              py="1em"
              border="1px"
              borderColor="#F2F2F2"
              as={Button}
              bg="#FFF"
              fontSize="sm"
              fontWeight="500"
              lineHeight="20px"
              _hover={{ bg: "#fff" }}
              leftIcon={<IoFilterSharp color="#999999" />}
            >
              Sort
            </MenuButton>
            <MenuList>
              <MenuItem
                _checked={{
                  border: "1px",
                  borderRadius: "lg",
                  borderColor: "#999999",
                }}
              >
                Approved
              </MenuItem>
              <MenuItem>Rejected</MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              py="1em"
              border="1px"
              borderColor="#F2F2F2"
              as={Button}
              bg="#FFF"
              fontSize="sm"
              fontWeight="500"
              lineHeight="20px"
              _hover={{ bg: "#fff" }}
              leftIcon={<IoFunnelOutline color="#999999" />}
            >
              Filter
            </MenuButton>
            <MenuList>
              <MenuItem
                _checked={{
                  border: "1px",
                  borderRadius: "lg",
                  borderColor: "#999999",
                }}
              >
                Approved
              </MenuItem>
              <MenuItem>Rejected</MenuItem>
            </MenuList>
          </Menu>
        </HStack> */}
      </HStack>
    </Box>
  );
};
export default ArticlesFilter;
