import useSWR from "swr";
import { getTopPublishers } from "../services/services";

export default function useTopPublishers() {
  const url = "/metrics/getWeeklyTopPublishers";
  const { data: publishers, isLoading } = useSWR(url, getTopPublishers, {
    suspense: false,
    revalidateIfStale: true,
    refreshInterval: 4000,
  });
  return { publishers, isLoading };
}
