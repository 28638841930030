import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import instance from "../../../services/api";
import { getUserToken } from "../../../services/services";

export const getData = createAsyncThunk(
  "metrics/getData",
  async (page: number, thunkApi) => {
    const url = "/metrics/advanceMetrics";
    return instance
      .get(url, {
        headers: {
          Authorization: ` Bearer ${getUserToken()} `,
        },
      })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const err = error.response.data;
          return thunkApi.rejectWithValue(err);
        }
      });
  },
);
