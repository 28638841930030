/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom } from "recoil";

export type ArticleState = "create" | "edit";
export type ArticleType = "published" | "draft";

interface ICreateArticleAtoms {
  categoryId: string;
  catergoryName: string;
  title: string;
  content: string | any;
  file: File | null;
  articleMode: ArticleState;
  articleType: ArticleType;
}

const defaultValue: ICreateArticleAtoms = {
  categoryId: "",
  catergoryName: "",
  title: "",
  content: "",
  file: null,
  articleMode: "create",
  articleType: "published",
};

export const createArticleState = atom<ICreateArticleAtoms>({
  key: "createArticleAtoms",
  default: defaultValue,
});
