import { SetStateAction, createContext, useMemo, useState } from "react";

export type authUsers = {
  phoneNumber: string;
  countryCode: string;
  email: string;
  lastName: string;
  firstName: string;
  profilePic: Array<string>;
  userId: string;
  role: {
    admin: boolean;
    publisher: boolean;
    advancer: boolean;
  };
};

type authContextType = {
  user: authUsers | null;
  setUser: React.Dispatch<SetStateAction<authUsers | null>>;
};
type userProviderprops = {
  children: React.ReactNode;
};
const AuthContext = createContext({} as authContextType);

function AuthContextProvider({ children }: userProviderprops) {
  const [user, setUser] = useState<authUsers | null>(null);
  const userAuthValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <AuthContext.Provider value={userAuthValue}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContextProvider, AuthContext };
